import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";

import { useAuth } from '../src/Hooks/useAuth.js'

// My components
import RoleCode from "./Components/RoleCode.js";
import Interview from "./Components/Interview.js";
import SignUp from "./Components/SignUp.js"
import SignIn from "./Components/SignIn.js"

/* import VideoJS from "./Components/VideoJS.js"; */

function App() {
  const { user, signout } = useAuth()
  const [errorMessage, setErrorMessage] = useState("");
  const [role, setRole] = useState({})
  const [applicationSubmitted, setApplicationSubmitted] = useState(false)
  const [signInForm, setSignInForm] = useState(false)

  useEffect(() => {
    document.title = "Push Recruitment"
  }, [role, user, applicationSubmitted]);

  const reset = () => {
    setRole({})
    setApplicationSubmitted(false)
  }

  return (
    <Container>

      {!applicationSubmitted ?
        <>
          {!role._id &&
            <Row className="d-flex vh-100 align-items-center justify-content-center">
              <Col className="col-12 col-lg-6">
                <RoleCode setRole={setRole} />
                {errorMessage && <div>{errorMessage}</div>}
              </Col>
            </Row>
          }

          {!user && role._id &&
            <Row className="d-flex vh-100 align-items-center justify-content-center">
              <Col className="col-12 col-lg-8">
                {signInForm ?
                  <SignIn role={role} setSignInForm={(v) => setSignInForm(v)} />
                  :
                  <SignUp role={role} setSignInForm={(v) => setSignInForm(v)} />
                }
              </Col>
            </Row>
          }

          {user && role._id &&
            <Row className="d-flex vh-100 align-items-center justify-content-center">
              <Col className="col-12 col-lg-10">
                <Interview role={role} reset={reset} setApplicationSubmitted={setApplicationSubmitted} />
                {errorMessage && <div>{errorMessage}</div>}
              </Col>
            </Row>
          }
        </>
        :
        <>
          <Row className="d-flex vh-100 align-items-center justify-content-center">
            <Col className="col-12 col-lg-6">
            <Card>
              <Card.Header>
              <Card.Title>Application Submitted</Card.Title>
              </Card.Header>
              <Card.Body className="p-5 text-center">
                <p>Thank you for taking the time do complete your video profile.</p>
                <p>You can close this window now.</p>
              </Card.Body>
            </Card>
            </Col>
          </Row>
        </>
      }
    </Container>
  );
}

export default App;
