import React, { useState } from 'react';
import { Button, Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useAuth } from '../Hooks/useAuth.js'
import LoadingIndicator from './LoadingIndicator.js';

const SignIn = (props) => {
    const { user, signin } = useAuth()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    const signIn = async () => {
        setLoading(true)

        if(email && password) {
            await signin(email, password)
            setLoading(false)
        }        
    }

    return (
        <Card className="mx-auto p-1 p-lg-5">
            <h1>Elevate your application.</h1>
            <Card.Body>
                <p>You're applying for the position of <b>{props.role && props.role.title}</b></p>
                <hr />
                <Row>
                    <Col><h3 className='my-3'>Login</h3></Col>
                    <Col className="d-flex align-items-center justify-content-end">
                        <button className="text-button text-small" onClick={ () => props.setSignInForm(false) }>
                            <u>Not a member? Sign up</u>
                        </button>
                    </Col>
                    </Row>
                <Form>
                    <Row>
                        <Col className="col-12 col-lg-6">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <FloatingLabel
                                    controlId="floatingEmailLabel"
                                    label="Email address"
                                    className="mb-3"
                                >
                                    <Form.Control
                                        type="email"
                                        placeholder="name@email.com"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </FloatingLabel>
                            </Form.Group>
                        </Col>
                        <Col className="col-12 col-lg-6">
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <FloatingLabel controlId="floatingPasswordLabel" label="Password">
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </FloatingLabel>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                        { !loading ?
                            <Button
                                className="float-end"
                                variant="primary"
                                onClick={() => signIn()}>
                                Login
                            </Button>
                            :
                            <button
                                className="float-end text-button fs-2">
                                { <LoadingIndicator /> }
                            </button>
                            }
                        </Col>
                    </Row>

                </Form>
            </Card.Body>
        </Card>
    )
}

export default SignIn