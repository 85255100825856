import { getFirestore, collection, query, where, getDocs, addDoc, serverTimestamp, doc, getDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { firestore, storage } from '../Hooks/useAuth.js'

/**
 * Used to return the role based on a specific code provided by the end user.
 * 
 * @param {string} code 
 * @returns {role}
 */
export const getRoleWithCode = async (code) => {
  try {
    const rolesRef = collection(firestore, 'roles');
    const q = query(rolesRef, where('code', '==', code));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return { error: "Role not found" };
    }

    const doc = querySnapshot.docs[0];
    const role = {
      _id: doc.id,
      ...doc.data()
    };

    return role;
  } catch (error) {
    console.error('Error retrieving roles:', error);
    return { error: "Error retrieving role" };
  }
}

/**
 * Accepts a blob generated by videojs-record and saves it to firebase storage.
 *  * 
 * @param {blob} blob 
 */
export const saveBlobToFirebaseStorageAsFile = (blob, setProgress) => {
  return new Promise((resolve, reject) => {
    const file = new File([blob], blob.name);
    const storageRef = ref(storage, `/files/answers/${blob.name}`);
    const uploadTask = uploadBytesResumable(storageRef, blob);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Upload progress code
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
  
        console.log("Uploading: " + percent + "%")
        setProgress(percent)

        if (percent == 100) {
          console.log("Finished uploading")
        }
      },
      (err) => {
        console.log("Error: ", err);
        reject({ error: "Unable to save to Firebase storage" });
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          resolve(url);
        });
      }
    );
  });
};

export const saveApplicationToFirebase = (application) => {
  const db = getFirestore()
  const colRef = collection(db, "applications")

  addDoc(colRef, {
      user: application.user,
      roleId: application.roleId,
      roleOwner: application.roleOwner,
      answers: application.answers,
      createdAt: serverTimestamp(),
  })
      .then(() => {
          /* console.log("Added new application") */
          console.log("Created new application.")
      })
      .catch((err) => {
          console.log(err.message)
      })
}

/**
 * Returns the applications for the user
 * 
 * @param {*} user 
 * @param {*} setApplications 
 */
export const getApplicationsForUser = async (user, setApplications) => {
  const db = getFirestore();
  const colRef = collection(db, "applications");
  const rolesColRef = collection(db, "roles");
  const userColRef = collection(db, "userInformation")

  const q = query(colRef, where("roleOwner", "==", 'ben@aclgroup.nz'));

  try {
    const querySnapshot = await getDocs(q);
    const applications = querySnapshot.docs.map((doc) => doc.data());

    // Retrieve role details and user information for each application
    const applicationsWithRoleDetails = await Promise.all(
      applications.map(async (application) => {
        const roleId = application.roleId;
        const roleDocRef = doc(rolesColRef, roleId);
        const roleDocSnapshot = await getDoc(roleDocRef);
        if (roleDocSnapshot.exists()) {
          const roleData = roleDocSnapshot.data();
          // Merge role details into the application object
          application = { ...application, role: roleData };
        }

        const userRef = application.user;
        const userQuerySnapshot = await getDocs(query(userColRef, where("userRef", "==", userRef)));
        const userDocs = userQuerySnapshot.docs;
        
        if (userDocs.length > 0) {
          const userDocSnapshot = userDocs[0];
          const userData = userDocSnapshot.data();
          // Merge user information into the application object
          application = { ...application, userInformation: userData };
        } else {
          console.log("Snapshot doesn't exist")
        }

        return application;
      })
    );

    console.log("Received some applications", applicationsWithRoleDetails);
    setApplications(applicationsWithRoleDetails);
  } catch (error) {
    console.error("Error getting applications: ", error);
    throw error;
  }
};

