import React, { useEffect, useState } from 'react';
import Interview from './Interview';
import VideoJS from './VideoJS';
import { Button, Col, Row } from 'react-bootstrap';

const InterviewReview = (props) => {

    useEffect(() => {

    }, [])


    function getVideoTypeFromURL(url) {
        const extension = url.substring(url.lastIndexOf('.') + 1);
        switch (extension) {
          case 'mp4':
            return 'video/mp4';
          case 'webm':
            return 'video/webm';
          case 'mkv':
            return 'video/x-matroska';
          // Add more cases for other video formats if needed
          default:
            return '';
        }
      }
      

    const [videoOptions, setVideoOptions] = useState({
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
          src: props.src,
          type: getVideoTypeFromURL(props.src),
        }]
    })

    return (
        <Row>
          <Col>
        <h1>{props.application.userInformation.firstName} {props.application.userInformation.lastName}</h1>
        <div>
          e. <a href={`mailto:${props.application.userInformation.email}`}>{props.application.userInformation.email}</a>
        </div>
        <div className='content-font fs-5 py-3'>{props.application.role.questions[props.index]}</div>
        <VideoJS key={props.answer.answerURL} options={videoOptions} />
        <div className='my-3'><a href={props.answer.answerURL} target="_blank">Video link</a></div>
        
        { props.index > 0 &&
          <Button onClick={props.prevVideo} className='float-start'>Previous Video</Button>
        }
        { props.index < props.videoCount &&
        <Button onClick={props.nextVideo} className='float-end'>Next Video</Button>
        }
        </Col>
        </Row>
    )
}

export default InterviewReview