import React, { useState } from 'react';
import { Button, Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useAuth } from '../Hooks/useAuth.js'
import { saveBlobToFirebaseStorageAsFile } from '../Firebase/Firebase.js';
import LoadingIndicator from './LoadingIndicator.js';
const SignUp = (props) => {
    const { user, signup } = useAuth()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()

    const signUp = async (e) => {
        e.preventDefault()
        setLoading(true)
        await signup(email, password, firstName, lastName)
        setLoading(false)
    }

    return (
        <Card className="mx-auto p-1 p-lg-4">
            <h1>Elevate your application.</h1>
            <Card.Body>
                <p>You're applying for the position of <b>{props.role && props.role.title}</b></p>
                <hr />
                <Row>
                    <Col><h3 className='my-3'>Sign up</h3></Col>
                    <Col className='d-flex align-items-center justify-content-end'>
                            <button className="text-button text-small" onClick={() => props.setSignInForm(true)}><u>Already a member? Login</u></button>
                        </Col>
                </Row>
                <Form onSubmit={signUp}>
                    <Row>
                        <Col className="col-12 col-lg-6">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <FloatingLabel
                                    controlId="floatingEmailLabel"
                                    label="Email address"
                                    className=""
                                >
                                    <Form.Control
                                        type="email"
                                        placeholder="name@email.com"
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </FloatingLabel>
                                <Form.Text className="text-muted text-small">
                                    We don't share your emails.
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col className="col-12 col-lg-6">
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <FloatingLabel controlId="floatingPasswordLabel" label="Password">
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </FloatingLabel>
                                <Form.Text className="text-muted text-small">
                                    Minimum of 8 characters
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12 col-lg-6">
                            <Form.Group className="mb-3" controlId="formFirstName">
                            <FloatingLabel controlId="floatingPasswordLabel" label="First name">
                                    <Form.Control
                                        type="text"
                                        placeholder="First name"
                                        onChange={(e) => setFirstName(e.target.value)}
                                        required
                                    />
                                </FloatingLabel>
                                <Form.Text className="text-muted text-small">
                                    This is the name people will see
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col className="col-12 col-lg-6">
                            <Form.Group className="mb-3" controlId="formLastName">
                            <FloatingLabel controlId="floatingPasswordLabel" label="Last name">
                                    <Form.Control
                                        type="text"
                                        placeholder="Last name"
                                        onChange={(e) => setLastName(e.target.value)}
                                        required
                                    />
                                </FloatingLabel>
                                <Form.Text className="text-muted text-small">
                                    This won't be visible until is needs to be
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        { !loading ?
                            <Button
                            className="float-end"
                            variant="primary"
                            type="submit">
                                Sign up
                            </Button>
                            :
                            <button
                                className="float-end text-button fs-2">
                                { <LoadingIndicator /> }
                            </button>
                            }
                        </Col>
                    </Row>

                </Form>
            </Card.Body>
        </Card>
    )
}

export default SignUp