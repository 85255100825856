import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table, Button } from 'react-bootstrap';
import VideoComponent from './VideoComponent.js';
import { RiExternalLinkLine } from 'react-icons/ri'
import { MdDone } from 'react-icons/md'
import ReadMeModal from './ReadMeModal.js';
import VideoModal from './VideoModal.js';
import { saveApplicationToFirebase, saveBlobToFirebaseStorageAsFile } from '../Firebase/Firebase.js';
import { useAuth } from '../Hooks/useAuth.js'

const Interview = (props) => {

    const { user, signout } = useAuth()
    const [answers, setAnswers] = useState(Array.from({ length: props.role.questions.length }, () => null))
    const [application, setApplication] = useState({
        user: user.uid,
        roleId: props.role._id,
        roleOwner: props.role.owner,
        answers: answers,
    })
    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [readyToSubmit, setReadyToSubmit] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    const [modalVideoShow, setModalVideoShow] = useState(false)
    const [loadingModal, setShowLoadingModal] = useState(false)
    const [progress, setProgress] = useState(50)

    useEffect(() => {

    }, [])

    // When a new recording is stopped, 
    const saveInterviewAnswer = async (blob) => {
        setUploading(true)

        saveBlobToFirebaseStorageAsFile(blob, setProgress)
            .then((url) => {
                /* console.log("Saved url: ", url); */

                setUploading(false)

                /* console.log("Adding answer to index: ", currentQuestion) */

                const newAnswer = {
                    index: currentQuestion,
                    question: props.role.questions[currentQuestion],
                    answerURL: url,
                };

                const updatedAnswers = [...answers];
                updatedAnswers.splice(currentQuestion, 1, newAnswer);
                setAnswers(updatedAnswers);

                let answerCount = 1
                answers && answers.map((answer) => {
                    answer && answer.answerURL && answerCount++ 
                } )

                if(answerCount === props.role.questions.length) {
                    setReadyToSubmit(true)
                }

            })
            .catch((error) => {
                /* console.error("Error saving blob to Firebase storage:", error); */
            });
    };

    const saveApplication = () => {

        const obj = {...application, answers}
        /* console.log("Obj: ", obj) */
        saveApplicationToFirebase(obj)
        
        props.setApplicationSubmitted(true)
    }

    const removeVideo = () => {
        const updatedAnswers = [...answers];
        updatedAnswers.splice(currentQuestion, 1, null);
        setAnswers(updatedAnswers);
    }

    const logout = () => {
        props.reset()
        signout()
    }

    return (
        <>
            <Card className="px-5 ">
                <Card.Body className='px-0'>
                    <Row>
                        <Col className='col-12'>
                            <h1 className=''>Let's get you hired.</h1>
                            <p>You are interviewing for the position of <strong> {props.role.title} </strong> </p>
                            <div className='text-small text-muted py-1'>{props.role.advertURL && <><a href={props.role.advertURL} target="_blank" rel="noreferrer">View role <sup><RiExternalLinkLine size="12" /></sup></a></>}</div>
                            <div className='text-small text-muted py-1'>{props.role.companyWebsite && <><a href={props.role.companyWebsite} target="_blank" rel="noreferrer">Company website <sup><RiExternalLinkLine size="12" /></sup></a> </>}</div>
                        </Col>
                    </Row>
                    <Row className='pb-3'>
                        <Col>
                            <h3 className='pt-5'>Questions</h3>
                            <div className=''>Select a question below to start. Don't worry, it won't start recording until you press record.</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='col-12 col-lg-8 d-flex flex-column'>
                            <Table hover className='mb-4'>
                                <thead>
                                    <tr>
                                        <th>Question</th>
                                        <th className='text-center'>
                                            <MdDone className="text-success" size="24" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='pointer'>
                                    {props.role.questions && props.role.questions.map((question, idx) => {
                                        return (
                                            <tr key={idx}
                                                onClick={() => {
                                                    setCurrentQuestion(idx)
                                                    setModalVideoShow(true)
                                                    setProgress(0)
                                                }}>
                                                <td>{question}</td>
                                                <td className='text-center'>{answers[idx] && answers[idx].answerURL && <MdDone className="text-success" />}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            <button
                                className='mt-auto py-3 py-lg-0 text-small text-button'
                                onClick={() => { setModalShow(true) }}
                            >First time? Read this.</button>
                        </Col>
                        <Col className='col-12 col-lg-4 d-flex flex-column'>
                            <Button
                                onClick={saveApplication}
                                className='float-end'
                                disabled={!readyToSubmit}
                                variant={!readyToSubmit ? 'secondary' : 'success'}
                            >Submit Application</Button>
                            <div className='mt-auto py-3 py-lg-0'>
                                <button className='float-end text-small text-button'
                                    onClick={logout}>Log out</button>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <ReadMeModal show={modalShow} onHide={() => { setModalShow(false) }} />
            <VideoModal
                show={modalVideoShow}
                saveInterviewAnswer={saveInterviewAnswer}
                answers={answers} 
                role={props.role}
                removeVideo={removeVideo}
                currentQuestion={currentQuestion}
                setProgress={ (p) => setProgress(p) }
                progress={progress}
                onHide={ () => { setModalVideoShow(false) } }
            />
        </>
    )
}

export default Interview