import React, { useState, useEffect, useContext, createContext } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { 
    getAuth, 
    signOut,
    signInWithEmailAndPassword, 
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    confirmPasswordReset,
    onAuthStateChanged,
} from "firebase/auth"

const app = initializeApp({
    apiKey: "AIzaSyBkiiNaZGvWMXYxjvS6X_xAHP6reGa6OVY",
    authDomain: "push-recruitment.firebaseapp.com",
    projectId: "push-recruitment",
    storageBucket: "push-recruitment.appspot.com",
    messagingSenderId: "1041047780625",
    appId: "1:1041047780625:web:9ec345bfe3eabc67faa0b0",
    measurementId: "G-XHG5LHH17Z"
});

// Initialize Firebase
export const firestore = getFirestore(app);
export const storage = getStorage()
const auth = getAuth(app);
const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = async (email, password) => {
    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      setUser(response.user);
      return response.user;
    } catch (error) {
      // Handle error if signInWithEmailAndPassword fails
      console.error(error);
    }
  };

/**
 * Sign up accepts, email, password, first and last name and creates a user account, and stores the information 
 * in the userInformation collection.
 */
  const signup = async (email, password, firstName, lastName) => {
    try {
      const response = await createUserWithEmailAndPassword(auth, email, password);
      const user = response.user;
      const db = getFirestore()
      
      // Create a document in the "userInformation" collection with user information
      await setDoc(doc(db, "userInformation", user.uid), {
        firstName: firstName,
        lastName: lastName,
        email: user.email,
        userRef: user.uid,
      })
  
      setUser(user);
      return user;
    } catch (error) {
      console.log(error)
    }
  };
  

  const signout = () => {
      signOut(auth)
      .then(() => {
        setUser(false);
      });
  };

  const sendPasswordResetEmail = (email) => {
    sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    user,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };
}