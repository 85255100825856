import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
/* import { CustomCallback } from './LinkedInCallback.js'; */
import './App.css'
import { ProvideAuth } from "./Hooks/useAuth.js"
import Admin from './Components/Admin';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <ProvideAuth>
      <BrowserRouter>
      <Routes>
        {/* <Route path='/linkedin' element={ < CustomCallback /> } />  */}
          <Route path="/" element={ <App /> } />
          <Route path="/admin" element={ <Admin /> } />
      </Routes>
      </BrowserRouter>
      </ProvideAuth>
    </React.StrictMode>
)