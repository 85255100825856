import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import recordImage from '../img/record-image.png'

const ReadMeModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h3>Instructions</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-5'>
                    <ul>
                    <p><li>Select a question by clicking on it.</li></p>
                    <p><li>Enable your webcam by clicking on the video recorder: <img src={recordImage} className='image-small ms-3' alt={"Video recorder icon"} /></li></p>
                        <p><li>When you're ready to record, press the record button.</li></p>
                        <p><li>Videos last for 30 seconds but you can stop it when you've finished your answer.</li></p>
                        <p><li>You can have as many attempts as you like. It's about making you look great.</li></p>
                    </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ReadMeModal