import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import videojs from 'video.js';
import VideoJS from './VideoJS';

const VideoModal = (props) => {

    const playerRef = useRef(null);
    const [recording, setRecording] = useState(false)
    const [loading, setLoading] = useState(false)

    const videoJsOptions = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        plugins: {
            record: {
                audio: true,
                video: true,
                maxLength: 30,
                debug: true,
                mimeType: 'video/webm',
                recordMimeType: 'video/webm;codecs=vp9',
            }
        }
    };

    useEffect(() => {
        if(props.progress === 100) { 
            setLoading(false) 
        }

    }, [props.answers, props.progress])

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            videojs.log('player will dispose');
        });

        // error handling
        player.on('deviceError', function () {
            console.log('device error:', player.deviceErrorCode);
        });

        player.on('error', function (element, error) {
            console.error(error);
        });

        // user clicked the record button and started recording
        player.on('startRecord', function () {
            setRecording(true)
        });

        // user completed recording and stream is available
        player.on('finishRecord', function () {
            console.log("Finished recording, should set buttons to disabled")
            setRecording(false)
            setLoading(true)

            const blob = { stream: player.recordedData }

            props.saveInterviewAnswer(player.recordedData)

        });

        // Enable the camera when the player is ready
        const videoDevice = player.record().getDevice('video');
        if (videoDevice) {
            videoDevice.enabled(true);
        }

    };

    const redoVideo = () => {
        props.removeVideo(props.currentQuestion)
    }

    const startRecording = () => {
        const player = playerRef.current;
        if (player) {
            player.record().start();
        }
    };

    const stopRecording = () => {
        const player = playerRef.current;
        if (player) {
            player.record().stop();
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="md"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter" className='w-100'>
                    <h4 className='text-center'>{props.role.questions[props.currentQuestion] && props.role.questions[props.currentQuestion]}</h4>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className='p-0'>
                {loading && <ProgressBar style={{ "borderRadius": "0" }} now={props.progress} />}
                {props.answers[props.currentQuestion] && props.answers[props.currentQuestion].answerURL ?
                    <video id="myVideo" className='w-100' controls src={props.answers[props.currentQuestion].answerURL} />
                    :
                    <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
                }
            </Modal.Body>

            <Modal.Footer>
                {!recording && !props.answers[props.currentQuestion] &&
                    <Button className='fs-6'
                        disabled={loading}
                        onClick={startRecording} variant='success'>
                        Start
                    </Button>
                }

                {!recording && props.answers[props.currentQuestion] &&
                    <Button
                        className='fs-6'
                        disabled={loading}
                        onClick={
                            redoVideo
                        }
                        variant='success'>
                        Restart
                    </Button>
                }

                {recording &&
                    <Button className='fs-6' disabled={loading} onClick={stopRecording} variant='success'>Stop</Button>
                }

                <Button className="fs-6" disabled={loading} onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default VideoModal

