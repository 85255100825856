import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Form, InputGroup } from 'react-bootstrap';
import { AiOutlineLoading } from 'react-icons/ai/index.esm.js'

// Firebase
import { getRoleWithCode } from '../Firebase/Firebase.js'

const RoleCode = (props) => {

    const [loading, setLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [error, setError] = useState(null)

    const inputRef = useRef('')

    useEffect(() => {

    }, [])

    const checkRoleCode = async () => {
        setLoading(true)
        const roleCode = inputRef.current.value;

        const role = await getRoleWithCode(roleCode)
        
        if(role.error) {
            setError("Code not found.")
            setLoading(false)
        } else {
            props.setRole(role)
        }
    }

    const handleRoleCodeChange = () => {
        setError(null)
        const value = inputRef.current.value.toUpperCase();
        inputRef.current.value = value;

        const inputValue = inputRef.current.value;
        setIsDisabled(inputValue.length !== 5)
    }

    return (
                <Card className="p-5">
                    <h1 className='pb-2'>Kia ora</h1>
                    <div className="p2-5">Elevate your application with a video profile</div>
                    <Card.Body className='mx-auto'>
                    <div className='text-small text-danger py-1'>{ error && error }</div>
                        <InputGroup className="mb-3">
                            <Form.Control
                                ref={inputRef}
                                onChange={handleRoleCodeChange}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        checkRoleCode()
                                    }
                                }}
                                placeholder="Enter your role code"
                                aria-label="Role code"
                                aria-describedby="Role code"
                                className="text-center no-outline"
                            />

                            {!isDisabled &&
                                <Button
                                    className='bg-dark'
                                    onClick={checkRoleCode}
                                    disabled={isDisabled}
                                >{!loading ? "Go" : <AiOutlineLoading className="icon-spin" />} </Button>
                            }
                        </InputGroup>
                        <div className='text-muted text-small text-center'>Use the 5 character code from your recruiter.</div>
                    </Card.Body>
                </Card>
    )
}

export default RoleCode