import React, { useEffect, useState } from 'react';
import { Button, Col, Container, FloatingLabel, Form, Row, Table } from 'react-bootstrap';
import { getApplicationsForUser } from '../Firebase/Firebase';
import { useAuth } from '../Hooks/useAuth.js'
import VideoJS from './VideoJS';

import InterviewReview from './InterviewReview';

const Admin = () => {

    const { user } = useAuth()
    const [applications, setApplications] = useState() // used to store all applications
    const [selectedRole, setSelectedRole] = useState() // used to store the selected role
    const [selectedCandidate, setSelectedCandidate] = useState() // Used to store the selected candidate
    const [currentVideo, setCurrentVideo] = useState(0) // Used to store the current video that is being displayed

    const [adminAccess, setAdminAccess] = useState(false)

    useEffect(() => {
        getApplicationsForUser(user, setApplications)

    }, [])

    useEffect(() => {
        // Set the initial role
        if (applications && applications.length > 0) {
            const roleId = applications[0].roleId;
            setSelectedRole(roleId);
        }
        
        // Set the initial candidate
        if (applications && applications.length > 0) {
            const user = applications[0].user;
            setSelectedCandidate(user);
        }

    }, [applications])

    const nextVideo = () => {
        setCurrentVideo(currentVideo + 1)
    }

    const prevVideo = () => {
        setCurrentVideo(currentVideo - 1)
    }

    const validateAdminAccess = (e) => {
        if (e.target.value == process.env.REACT_APP_ADMIN_ACCESS) {
            setAdminAccess(true)
        }
    }

    return (
        <Container>
            {adminAccess ?
                <Row className='p-5'>
                    <Col className='col-2 p-1'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Roles</th>
                                </tr>
                            </thead>
                            <tbody>
                                {applications &&
                                    Array.from(new Set(applications.map(app => app.role.roleId))).map((roleId, index) => {
                                        const role = applications.find(app => app.role.roleId === roleId);
                                        return (
                                            <tr key={role.roleId}>
                                                <td className={`pointer ${selectedRole === role.roleId ? ' selected' : ''}`} onClick={() => {
                                                    setSelectedRole(role.roleId)
                                                    setCurrentVideo(0)
                                                }}>{role.role.title} <br /><span className='text-small'><b>Role code:</b> {role.role.code}</span></td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </Col>

                    <Col className='col-3 p-1 overflow-hidden'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Candidates</th>
                                </tr>
                            </thead>
                            <tbody>
                                {applications &&
                                    Array.from(new Set(applications.map(app => app.user))).map(user => {
                                        // Find the application with matching user
                                        const application = applications.find(app => app.user === user);
                                        if (application && application.userInformation) {
                                            const { firstName, lastName } = application.userInformation;
                                            const fullName = `${firstName} ${lastName}`;

                                            return (
                                                <tr key={user}>
                                                    <td
                                                        className={`pointer ${selectedCandidate === application.user ? ' selected' : ''}`}
                                                        onClick={() => {
                                                            setSelectedCandidate(user);
                                                            setCurrentVideo(0);
                                                        }}
                                                    >
                                                        {fullName}
                                                    </td>
                                                </tr>
                                            );
                                        }

                                        return null;
                                    })}

                            </tbody>
                        </Table>

                    </Col>

                    <Col className='col-6'>
                        {applications &&
                            applications.map((application) => (
                                <div key={application.id}>
                                    {application.answers &&
                                        application.answers.map((answer, index) => {
                                            if (application.roleId === selectedRole &&
                                                application.user === selectedCandidate &&
                                                index === currentVideo &&
                                                answer.answerURL
                                            ) {

                                                return (
                                                    <InterviewReview
                                                        application={application}
                                                        answer={answer}
                                                        src={answer.answerURL}
                                                        index={currentVideo}
                                                        videoCount={application.answers.length - 1} // Updated line
                                                        nextVideo={nextVideo}
                                                        prevVideo={prevVideo}
                                                    />
                                                );
                                            }

                                            return null;
                                        })}

                                    {/* {(!selectedRole || !selectedCandidate) && "No videos"} */}
                                </div>
                            ))}
                    </Col>

                </Row>
                :
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicAccess">
                            <FloatingLabel controlId="floatingPasswordLabel" label="Password">
                                <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    onChange={(e) => validateAdminAccess(e)}
                                    required
                                />
                            </FloatingLabel>
                            <Form.Text className="text-muted text-small">
                                Enter your admin password
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
            }
        </Container>
    )

}
export default Admin